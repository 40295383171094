import React from 'react';
import { AgisticsMultiItem, AgisticsMultiSelect } from './agistics-multi-select';
import { useUpdateUserPreferences } from '../hooks/use-update-user-preferences';

interface AgisticsMultiSelectWithPrefsProps<T> {
    selectedIds: T[];
    setSelectedIds: (s: T[]) => void;
    items: AgisticsMultiItem<T>[];
    label: string;
    prefKey: string;
    // some preferences remove items, others store the actual items selected
    removeMode: boolean;
    presist?: boolean;
    additionalOnChange?: (t: T[]) => void;
}

// Passed in items needs to be memoized until bug is fixed.
// BUG - useEffect is dependent on items changing, which then calls setSelectedIds which is passed in
// causing parent to re-render causing items to change (if not memoized).  Causing infinite loop.

// fix is likely? to set selectedIds prefs in parent and remove useEffect? I think...
// might also be remove items from useEffect dependency?  tho at this time I'm just trying to get code to prod
export const AgisticsMultiSelectWithPrefs = <T extends string | number>(props: AgisticsMultiSelectWithPrefsProps<T>) => {
    const { selectedIds, setSelectedIds, items, label, prefKey, removeMode, presist = false, additionalOnChange } = props;
    const { preferences, updatePreferences } = useUpdateUserPreferences();

    React.useEffect(() => {
        const prefs = preferences?.[prefKey] || [];
        const constructedItems = items.filter(item => removeMode ? !prefs.includes(item.id) : prefs.includes(item.id));
        setSelectedIds(constructedItems.map(x => x.id));
    }, [items]);

    const presistPrefsChange = (value: T[]) => {
        const newPreferences = {
            ...preferences,
            [prefKey]: value,
        }
        updatePreferences(newPreferences);
    }

    return (
        <AgisticsMultiSelect
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            items={items}
            label={label}
            presistPrefsChange={presist ? presistPrefsChange : undefined}
            additionalOnChange={additionalOnChange}
        />
    );
};
